@import "~@anytimefitness/hc-shared-styles/src/base/typography";

.member_payments_total {
    @include new-h3;
    font-weight: $bold_font-weight;
    color: map_get($new-color_blue, "normal");
    margin: 32px 0 16px;
}

.download_button_container {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}